<template>
  <div id="completedPanel">
    <div id="imageContainer">
      <img src="/monthlyReviewImages/commissions_setup_complete.png"/>
    </div>

    <div id="completedTextPanel">
      Awesome, your commissions plan is all set! Now you can view, update and finalize commissions.
    </div>
    <div id="completedButtonPanel" v-if="refreshingSetup === false">
      <v-btn :to="laterLink" plain>
        LATER
      </v-btn>
      <v-btn :to="completedLink" color="primary">
        FINALIZE TEAM COMMISSIONS
      </v-btn>
    </div>
  </div>
</template>

<script>
import {mapActions} from "vuex";

export default {
  data(){
    return {
      refreshingSetup: true
    }
  },
  mounted() {
    this.$api.commissionSetup.completeSetup().then(() =>{
      this.loadCommissionSetup().then(() => {
        this.refreshingSetup = false;
      });
    });
  },
  methods:{
    ...mapActions({
      loadCommissionSetup: 'commissions/loadCommissionSetup',
    })
  },
  computed: {
    laterLink() {
      let date = new Date()

      return {
        name: 'commission-overview', params: {
          year: date.getFullYear(),
          month: ('0' + (date.getMonth() + 1)).slice(-2),
        }
      }
    },
    completedLink(){
      let date = new Date()

      return {
        name: 'commission-statement.entry', params: {
          month: ('0' + (date.getMonth() + 1)).slice(-2),
          year: date.getFullYear(),
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
#completedPanel {
  width: 100%;
  background: #fff;
  padding-top: 80px;
  padding-bottom:40px;
  text-align: center;


  #imageContainer {
    width: 100%;
    text-align: center;
    margin-bottom: 80px;
  }

  #completedTextPanel {
    margin-bottom: 40px;
  }

  #completedButtonPanel {
    height: 200px;
  }
}
</style>
